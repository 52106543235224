




import { Component, Prop, Vue } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import YouTubePlayer from 'youtube-player'

import MasterLessonsModule from '@/store/modules/master/lessons'

@Component
export default class YoutubeIframeNative extends Vue {
  @Prop({ required: true })
  private src!: string

  @Prop({ default: 'youtube' })
  private namePlayer!: string

  @Prop({ default: null })
  private saveTimecode!: number | null

  @Prop({ required: true })
  private lessonID!: number

  @Prop({ required: true })
  private masterGroupID!: number

  private player: any = null
  private interval: number | null = null

  private method = this.saveTimeLesson.bind(this, true)

  private get videoID() {
    const match = this.src.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/live\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^&/]{11})/i)
    return match && match[1] ? match[1] : ''
  }

  private mounted() {
    this.$emit('destroyVideoRef')
    this.$nextTick(() => {
      this.player = YouTubePlayer(this.namePlayer, {
        playerVars: {
          start: this.saveTimecode !== null ? this.saveTimecode : undefined,
        },
        videoId: this.videoID,
      })
      this.player.on('ready', (e: any) => {
        this.$emit('readymetadata', e.target.getDuration())
        this.$bus.$on('jumpToTimeCode', this.handleJumpToTimeCode)
      })
      this.player.on('stateChange', (e: any) => {
        // остановка (конец видео)
        if (e.data === 0) {
          this.clearInterval()
          MasterLessonsModule.saveTimecodeLesson({
            lessonID: this.lessonID,
            masterGroupID: this.masterGroupID,
            seconds: 0,
            unload: false,
          })
          localStorage.removeItem(`save-timecode-${this.lessonID}-${this.masterGroupID}`)
        }

        // воспроизведение
        if (e.data === 1) {
          this.interval = setInterval(this.saveTimeInLocalStorage, 5000)
        }

        // пауза
        if (e.data === 2) {
          this.saveTimeLesson()
          this.clearInterval()
        }
      })
    })
    window.addEventListener('beforeunload', this.method)
  }

  private destroyed() {
    this.player?.destroy()
    this.clearInterval()
    this.$bus.$off('jumpToTimeCode', this.handleJumpToTimeCode as any)
    this.saveTimeLesson()
    window.removeEventListener('beforeunload', this.method)
  }

  private handleJumpToTimeCode (seconds: number) {
    this.player.seekTo(seconds)
    this.player.playVideo()
  }

  // вызывается при паузе на плеере или событии beforeUnload
  private async saveTimeLesson(unload = false) {
    const seconds = Math.floor(await this.player.getCurrentTime())
    const duration = await this.player.getDuration()
    if (this.saveTimecode !== seconds && Math.floor(duration) !== seconds) {
      MasterLessonsModule.saveTimecodeLesson({
        lessonID: this.lessonID,
        masterGroupID: this.masterGroupID,
        seconds,
        unload,
      })
    }
  }

  private clearInterval() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  private async saveTimeInLocalStorage() {
    localStorage.setItem(`save-timecode-${this.lessonID}-${this.masterGroupID}`, JSON.stringify({
      seconds: Math.floor(await this.player.getCurrentTime()),
      ts: Date.now(),
    }))
  }
}
